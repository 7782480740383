<!--
 * @Descripttion: 微信支付订单分账记录
 * @version: 
 * @Author: dxl
 * @Date: 2023-08-20 14:26:00
 * @LastEditors: dxl
 * @LastEditTime: 2023-08-24 12:07:56
-->
<template>
    <div class="LedgerOrderSplitList">
        <div class="title-wrap">
            <div class="tag"></div>
            <div class="title">微信支付订单分账记录</div>
        </div>
        <div class="case-wrap">
            <div class="screen-wrap">
                <el-select clearable v-model="state" @change="stateChange" placeholder="请选择分账完成情况">
                    <el-option
                        v-for="item in stateOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                <div class="search-item">
                    <el-input clearable v-model="keyword" placeholder="请输入搜索内容"></el-input>
                    <el-button class="btns" @click="clickSearch" type="primary">搜索</el-button>
                </div>
            </div>
            <div class="table-wrap" v-loading="loading">
                <el-table :data="tableData" border stripe style="width: 100%">
                    <el-table-column align="center" prop="create_timex" label="分账时间" width="170" :show-overflow-tooltip="true"></el-table-column>
                    
                    <el-table-column align="center" prop="type_name" label="分账类型" width="100" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="price" label="分账金额" width="100" :show-overflow-tooltip="true"></el-table-column>
                    <el-table-column align="center" prop="state" label="分账状态" width="100" :show-overflow-tooltip="true">
                        <template slot-scope="scope">
                            <el-tag v-if="scope.row.state==0" size="medium" type="info">{{ scope.row.state_name }}</el-tag>
                            <el-tag v-if="scope.row.state==1" size="medium" type="success">{{ scope.row.state_name }}</el-tag>
                            <el-tag v-if="scope.row.state==-1" size="medium" type="danger">{{ scope.row.state_name }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column align="center" prop="err_msg" label="失败原因"  ></el-table-column>
                    <el-table-column align="center" prop="split_sn" label="微信分账单编号"  ></el-table-column>
                    <el-table-column align="center" prop="pay_sn" label="微信支付订单编号"  ></el-table-column>
                    <el-table-column align="center" prop="sn" label="平台分账编号"  ></el-table-column>
                </el-table>
                <div class="pagination-wrap">
                    <el-pagination
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="currentPageSize"
                        layout="total, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pageMixin from '@/internal/pageMixin'
import common from "@/utils/common.js"
import AliyunUpload from "@/components/upload/AliyunUpload.vue";
export default {
    components:{
        AliyunUpload,
    },
    mixins:[pageMixin],
    data () {
        return {
            pageMixin_index:'LedgerOrderSplitList',
            state: '',
            stateOptions:[
                {
                    value: 0,
                    label: '处理中'
                }
                ,{
                    value: 1,
                    label: '处理完成'
                }
                ,{
                    value: -1,
                    label: '失败'
                }
            ],
            loading: false,
            tableData: [],
            currentPage: 1,
            currentPageSize: 10,
            total: 0,
            keyword: '',
            commissionerParam:''
        }
    },
    mounted () {
        this.commissionerParam = this.$route.query
        if(!this.commissionerParam.pay_sn){
            this.$message.error("页面参数异常！");
        }else{
            this.getLedgerOrderSplitList();
        }
    },
    methods: {
        // 分账
        clickFZ(){

        },
        // 
        clickFZ_order(){

        },
        // 获取数据
        getLedgerOrderSplitList(){
            let params = {
                page: this.currentPage,
                page_size: this.currentPageSize,
                keyword: this.keyword,
                state: this.state,
                pay_sn: this.commissionerParam.pay_sn
            }
            this.loading = true;
            common.connect('/customerservicev1/Promoter/getPromoterOrderSplitList',params,(res)=>{
                this.total = res.data.count;
                this.tableData = res.data.list;
                this.loading = false;
            })
        },
        // 搜索
        clickSearch(){
            this.currentPage = 1;
            this.getLedgerOrderSplitList();
        },
        // 状态选择
        stateChange(){
            this.currentPage = 1;
            this.getLedgerOrderSplitList();
        },
        //翻页
        handleCurrentChange(val){
            this.currentPage = val;
            this.getLedgerOrderSplitList();
        },
    },
}
</script>

<style lang='scss'>
.pointer{
  cursor: pointer;
}
.LedgerOrderSplitList{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
        width: 4px;
        height: 20px;
        background: #409EFF;
        border-radius: 10px;
        }
        .title{
        font-size: 18px;
        font-weight: bold;
        padding-left: 10px;
        }
    }
    .case-wrap{
        background: #fff;
        padding: 20px;
        margin: 20px 0;
        border-radius: 6px;
        .screen-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-bottom: 20px;
            .search-item{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                margin-left: 20px;
                .el-input{
                    width: 220px;
                    margin-right: 10px;
                }
            }
        }
        .table-wrap{
            margin-top: 20px;
            .image{
                width: 40px;
                cursor: pointer;
            }
            .operation-wrap{
                .iconfont{
                    font-size: 17px;
                    padding: 0 10px;
                    cursor: pointer;
                }
                .iconfontx{
                    font-size: 17px;
                    color: red;
                    padding: 0 10px;
                    cursor: pointer;
                }
                .link{
                    cursor: pointer;
                    color: #409EFF;
                }
            }
            .pagination-wrap{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-top: 20px;
            }
        }
    }
    .dialog-wrap{
        width: 540px;
        .title{
            text-align: center;
            font-size: 18px;
            color: #000;
        }
        .content{
            margin-left: 20px;
            .el-input{
                width: 500px;
            }
            .el-textarea{
                width: 500px;
            }
            .item{
                padding-bottom: 20px;
                .tit{
                    padding-bottom: 20px;
                    color: #000;
                }
                .el-button{
                    width: 500px;
                }
                .upload-wrap{
                    .fileList-box{
                        position: relative;
                        width: 250px;
                        border: 1px solid #eee;
                        border-radius: 6px;
                        padding: 20px 20px;
                        .delFile{
                            position: absolute;
                            top: 5px;
                            right: 5px;
                            background: rgba(0, 0, 0, 0.2);
                            padding: 0 5px;
                            border-radius: 10px;
                            color: #fff;
                            cursor: pointer;
                            
                        }
                        .link{
                            width: 230px;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            .icon{
                                display: flex;
                                flex-wrap: wrap;
                                align-items: baseline;
                                .iconfont{
                                    font-size: 14px;
                                    margin-right: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .btn-wrap{
            display: flex;
            justify-content: center;
        }
    }
    .el-dialog{
        margin: 0;
        margin-top: 0vh !important;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        padding-bottom: 10px;
    }
}
</style>
